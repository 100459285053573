@import "../../assets/scss/main.scss";

.portfolio{
    background-image: url("../../assets/images/purple-gradient.jpeg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    &-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        padding: 7rem 4rem;
        overflow: hidden;
    }
    &-header{
        margin: 3rem 0 5rem 0;
    }
    &-projects{
        max-width: 50rem;
        width: 100%;
    }
}

@include bp-tab{
    .portfolio{
        &-container{
            padding: 17rem 0;
        }
        &-projects{
            padding: 0 14.5rem 0 10.5rem;
            max-width: 75rem;
            overflow: hidden;
        }
    }
}