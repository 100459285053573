@import "../../assets/scss/main.scss";

.bm {
  background-image: url("../../assets/images/bgmobilemeeting.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  &-container {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    min-height: 78rem;
    height: 100%;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 78rem;
    height: 100%;
    padding: 5rem;
    & h3 {
      font-size: 3rem;
      font-weight: 900;
      margin-bottom: 2rem;
    }
    & p {
      max-width: 70rem;
    }
    & button {
      color: white;
      font-size: 1.4rem;
      font-weight: 700;
      border: 0.1rem solid white;
      margin-top: 3rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & svg {
      color: white;
      font-size: 7rem;
      margin-top: 2rem;
    }
  }
}

@include bp-tab {
  .bm {
    &-container {
      & h3 {
        font-size: 3.5rem;
      }
      & p {
        font-size: 2rem;
      }
      & button {
        font-size: 1.5rem;
      }
    }
  }
}
@include bp-tab-md {
  .bm {
    background-image: url("../../assets/images/bm.jpg");
    &-container {
      & h3 {
        font-size: 4rem;
      }
      & p {
        font-size: 2.2rem;
      }
    }
  }
}
