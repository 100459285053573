@import "../../assets/scss/main.scss";

// #422D96
// #6D38D6
// #564FE5
// #4963E0
// #27ACC3

.md {
  background-image: linear-gradient(to bottom, #030303, #0a0a0a, #0d1017);

  display: grid;
  place-items: center;
  padding: 10rem 3rem;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-image {
    width: 28rem;
    margin: 3rem 0;
  }
  &-text {
    border-left: 0.1rem solid $gray;
    margin: 3rem 1rem;
    padding-left: 3rem;
    h3 {
      margin: 1rem 0 2rem 0;
    }
  }
}

@include bp-tab {
  .md {
    &-image {
      width: 40rem;
      padding: 0 2rem;
    }
    &-text {
      border-left: none;
      text-align: center;
      padding: 0 5rem;
      margin: 3rem 5rem;
    }
  }
}
@include bp-tab-md {
  .md {
    &-container {
      flex-direction: row-reverse;
    }
    &-text {
      text-align: left;
      width: 50rem;
      margin-left: -2rem;
    }
    &-image {
      padding: 0 2rem;
      margin-left: -3rem;
    }
  }
}

@include bp-lap {
  .md {
    &-text {
      width: 60rem;
      border: none;
    }
    &-image {
      width: fit-content;
      padding: 0 2rem;
      & img {
        width: 45rem;
      }
    }
  }
}
