h1, h2, h3, h4, h5, span{
    font-family: "Quicksand", sans-serif;
    color: white;
}

p, a, li, strong{
    font-family: "Inter", sans-serif;
}

p{
    font-size: 1.8rem;
    color: $gray;
}

strong{
    color: $primary;
}

.title{
    font-weight: 900;
    font-size: 2rem;
}

.small{
    font-size: 1.6rem;
}

@include bp-md{
    p{
        font-size: 1.9rem;
    }
    .title{
        font-size: 2.5rem;
    }
    .small{
        font-size: 1.7rem;
    }
}
@include bp-tab{
    p{
        font-size: 2rem;
    }
    .title{
        font-size: 3rem;
    }
}
@include bp-lap{
    .title{
        font-size: 4rem;
    }
}