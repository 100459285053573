@import "../../assets/scss/main.scss";

.tb{
    position: fixed;
    background-color: $dark;
    z-index: 99;
    height: 7rem;
    width: 100%;
    padding: 3rem 2rem;
    &-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    &-logo{
        display: flex;
        align-items: center;
        & img{
            width: 4rem;
        }
    }
    &-contact{
        display: flex;
        align-items: center;
        & a{
            color: $gray;
        }
    }
}

@include bp-tab{
    .tb{
        padding: 3rem 4rem;
    }
}