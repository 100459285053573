@import "../../assets/scss/main.scss";
.hero{
    background-image: linear-gradient(to bottom, #030303, #0a0a0a,#0D1017);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding-top: 7rem;
    &-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        padding: 4rem 0;
    }
    &-c2a{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        &-content{
            & span{
                color: $primary;
            }
        }
        &-btns{
            display: grid;
            place-items: center;
            width: 100%;
            margin-top: 10rem;

            & svg{
                font-size: 5rem;
                color: white;
            }
        }
    }
    &-logo{
        display: flex;
        align-items: center;

        & img{
            width: 5rem;
        }
    }
    &-image{
        width: 28rem;
        margin-top: 5rem;
    }
}
@include bp-md{
    .hero{
        &-image{
            width: 35rem;
        }
    }
}
@include bp-lg{
    .hero{
        &-image{
            width: 38rem;
        }
    }
}

@include bp-tab{
    .hero{
        &-c2a{
            &-content{
                width: 50rem;
            }
        }
        &-image{
            width: 60rem;
            margin-top: 7rem;
        }
    }
}

@include bp-tab-md{
    .hero{
        &-c2a{
            &-content{
                width: 55rem;
            }
            &-btns{
                margin-top: 6rem;
            }
        }
        &-image{
            margin-top: 5rem;
        }
    }
}

@include bp-lap{
    .hero{
        &-container{
            display: grid;
            place-items: center;
        }
        &-c2a{
            width: 100%;
            flex-direction: row;
            &-content{
                width: 40rem;
                margin-right: -15rem;
            }
            &-btns{
                margin-top: 6rem;
            }
        }
        &-image{
            width: 80rem;
            margin: 5rem -15rem 0 0;
        }
    }
}

@include bp-lap-lg{
    .hero{
        &-c2a{
            &-content{
                width: 50rem;
                margin-right: -15rem;
            }
        }
        &-image{
            width: 90rem;
            margin-right: -15rem;
        }
    }
}