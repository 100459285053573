@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "./app/assets/scss/main.scss";

*,  ::after, ::before {
    box-sizing: border-box;
}

html, body {
    font-size: 62.5%;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    background-color: $dark;
    overflow-x: hidden;
}