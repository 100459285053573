@import "../../assets/scss/main.scss";

.wd{
    display: grid;
    place-items: center;
    background-image: url("../../assets/images/purple-gradient.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10rem 3rem;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    &-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &-image{
        width: 29rem;
        margin: 3rem 0;
    }
    &-text{
        border-left: .1rem solid $gray;
        margin: 3rem 1rem;
        padding-left: 3rem;
        h3{
            margin: 1rem 0 2rem 0;
        }
    }
}

@include bp-tab{
    .wd{
        &-image{
            width: 50rem;
            padding: 0 2rem;
        }
        &-text{
            border-left: none;
            text-align: center;
            padding: 0 5rem;
            margin: 3rem 0;
        }
    }
}
@include bp-tab-md{
    .wd{
        &-container{
            flex-direction: row;
        }
        &-text{
            text-align: left;
            width: 50rem;
            border: none;
            margin: 0;
        }
        &-image{
            width: 50rem;
            padding: 0 2rem;
            margin: 0 2rem 0 -4rem;
        }
    }
}

@include bp-lap{
    .wd{
        &-text{
            width: 60rem;
        }
        &-image{
            width: 70rem;
            padding: 0 2rem;
            margin-right: 0;
        }
    }
}