@import "../../assets/scss/main.scss";
.pc {
  width: 100%;
  border-radius: 2rem;
  max-width: 50rem;
  &-container {
    width: 100%;
    height: 100%;
  }
  &-img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;

    & img {
      width: 100%;
      height: 30rem;
      object-fit: cover;
      border-radius: 1rem;
      padding: 0 1rem;
    }
  }
  &-text {
    text-align: center;
    display: grid;
    place-items: center;
    padding: 2rem;
    height: 30rem;
    display: none;

    & p {
      line-height: 3rem;
    }
  }

  &-actions {
    border-radius: 0 0 2rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 4rem;
    margin-top: 4rem;
    & button {
      width: 7rem;
      color: white;
      font-size: 1.3rem;
      margin: 0 0.5rem;
    }
    & svg {
      font-size: 3rem;
    }
  }
}

@include bp-tab {
    .pc{
        &-text{
            height: 20rem;
        }
    }
    .alice-carousel {
    margin-top: 7rem;
  }
  .__target{
    transform: scale(1.1);
    margin: 0 3rem !important;
  }
  .alice-carousel__dots {
    margin-top: 1rem !important;
    transform: translateX(3rem);
  }
  .alice-carousel__prev-btn, .alice-carousel__next-btn{
    margin-top: 1rem;
    transform: translateX(3rem);

  }

}
