// Color palette
// --------------------------
$primary: #41D4F2;
$dark: #030303;
$gray: #c7c7c7;

// Breakpoints
// --------------------------
$bp-sm: 319px;
$bp-md: 374px;
$bp-lg: 424px;
$bp-tab: 767px;
$bp-tab-md: 1023px;
$bp-lap: 1278px;
$bp-lap-lg: 1439px;
$bp-desk: 1920px;
$bp-desk-lg: 2559px;