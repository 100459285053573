@import "libs/variables";
@import "libs/mixins";
@import "base/typography";
@import "base/components";

.fade-in-horizontal {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 1.5s ease, transform 1.5s ease;

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-vertical {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 1.5s ease, transform 1.5s ease;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
}
