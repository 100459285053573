@import "../../assets/scss/main.scss";

.footer{
    display: grid;
    place-items: center;
    padding: 4rem 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-image: linear-gradient(to bottom, #030303, #0a0a0a,#0D1017);
}